const executeScriptTagsFrom = (element, componentName) => {
  if (element && element.querySelectorAll) {
    Array.from(element.querySelectorAll('script')).forEach(({ innerHTML }) => {
      try {
        eval(innerHTML)
      } catch (error) {
        console.error('banner eval', error)
        window.onerror('Banner error', componentName, 0, 0, error)
      }
    })
  } else {
    console.warn("Can't execute scripts for banner from element", element)
  }
}

export default executeScriptTagsFrom
